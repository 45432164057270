//Desktop
@media screen and (min-width: 992px) {
	#search-mobile {
		display: none;
	}

	#search {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 73px;
		opacity: 0;
		pointer-events: none;

		form {
			display: flex;
			align-items: center;
			height: 100%;
			width: calc(100% - 450px);
			margin-left: 400px;
			margin-right: 50px;
			padding: 10px 0;
			pointer-events: auto;

			@media screen and (max-width: 1439px) {
				width: calc(100% - 400px);
				margin-left: 350px;
			}

			@media screen and (max-width: 1199px) {
				width: calc(100% - 350px);
				margin-left: 300px;
			}

			label {
				flex: auto;
				display: block;
			}

			input[type='search'] {
				display: block;
				width: 100%;
				height: 50px;
				border: 0;
				border-bottom: 2px solid white;
				background: transparent;
				color: white;

				outline: none;

				&::-webkit-search-cancel-button {
					display: none;
				}
			}

			input[type='submit'] {
				display: none;
			}
		}
	}
}

//Mobile
@media screen and (max-width: 991px) {
	#search {
		display: none;
	}

	#navbar {
		.navbar-menu {
			#search-mobile {
				order: -100;
				width: 100%;

				padding: 10px 5px;
				border-bottom: 1px solid transparentize(white, 0.7);

				opacity: 1 !important; //override any inline styles added by JS

				.container {
					padding: 0;
				}

				form.search-form-mobile {
					position: relative;
					width: 100%;
					overflow: hidden;

					button[type="submit"] {
						position: absolute;
						left: 6px;
						top: 50%;

						z-index: 5;

						border: none;
						padding: 0 6px;
						background-color: transparent;

						transform: translateY(-50%);

						cursor: pointer;

						svg {
							width: 20px;
						}
					}

					input[type='search'] {
						width: 100%;
						height: 40px;

						padding: 5px 0 5px 40px;
						border: none;
						border-radius: 0;

						appearance: none; //Fixes an issue where iOS will add border-radius: 20px

						background-color: transparentize(white, 0.7);
						color: white;
						font-weight: 100;

						transition: width 150ms ease-in-out;

						&:focus {
							width: calc(100% - 65px);
							border-radius: 0;
						}

						&::placeholder {
							color: white;
							font-weight: 100;
							line-height: 23px; //Adjustment for iOS
						}
					}

					button.close {
						position: absolute;
						right: 0;
						top: 50%;

						opacity: 0;

						transform: translateY(-50%);

						background-color: transparent;
						color: $brand-primary;
						border: none;

						cursor: pointer;
					}

					&.focused {
						button.close {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
