html {
	color: $black;
	font-family: $font-family-base;
	font-weight: 200;

	line-height: 1.2;
}

body {
	position: relative;
	touch-action: manipulation;
	font-family: $font-family-base;

	overflow-x: hidden;
	max-width: 2560px;
	margin: 0 auto;

	#site-wrapper {
		min-height: 100vh;
		display: flex;
		flex-flow: column nowrap;
		justify-content: stretch;

		#primary {
			flex: 1 1 auto;
			background-color: $white;

			main {
				display: block;
			}
		}
	}
}

a {
	color: $font-color-link;
	text-decoration: none;
	transition: all 0.2s ease-in-out;

	&:hover {
		color: $font-color-link-hover;
		text-decoration: underline;
	}
}

p {
	margin: 0 0 10px 0;
	font-size: pxrem(16);
	line-height: 1.2;
}

address {
	font-style: normal;

	p {
		margin: 0;
	}
}

picture {
	img {
		vertical-align: middle;
	}
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}
