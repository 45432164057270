$blue-to-dark-purple: linear-gradient(
	to right,
	$bld-reflex-blue,
	$bld-purple-mid
);

$blue-to-bright-purple: linear-gradient(
	to right,
	$bld-reflex-blue,
	$bld-purple-bright
);
