// Fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
	Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
	monospace !default;

$font-family-base: ff-real-text-pro, "Helvetica Neue", Helvetica, Arial, sans-serif !default;

/*
	This section generate placeholder selectors for the website headers.
	The reason these are used is so we can use @extend in theme.scss and create the heading classes
	in the critical-css.scss file without have the classes duplicated in both output files.
*/
$header-color-map: (
	"primary": $brand-primary,
	"secondary": $brand-secondary,
	"white": $white,
);

$header-size-map: (
	"xl": 48,
	"lg": 40,
	"md": 32,
	"sm": 27,
	"xs": 21,
);

%bld-heading {
	margin-bottom: 15px;

	font-weight: 500;

	> a {
		color: inherit;
	}

	@each $name, $color in $header-color-map {
		&--#{$name} {
			@extend %bld-heading;
			color: $color;
		}
	}

	@each $name, $size in $header-size-map {
		&--#{$name} {
			@extend %bld-heading;
			font-size: pxrem($size);

			@if $name != xs {
				@media screen and (max-width: 991px) {
					font-size: pxrem($size * 0.7);
				}

				@media screen and (max-width: 479px) {
					font-size: pxrem($size * 0.6);
				}
			}
		}
	}
}
