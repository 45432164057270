.bg--blue {
	background-color: $bld-reflex-blue;
}

.bg--orange {
	background-color: $bld-orange-mid;
}

.bg--pink {
	background-color: $bld-pink-bright;
}

.bg--purple {
	background-color: #d4c7e0;
}

.bg--gradient {
	background-image: $blue-to-dark-purple;
}

.bg--textured-gray {
	background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-gray.png');
}

.bg--textured-offwhite {
	background-color: #e8e8da;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-offwhite.png');
	}
}

.bg--textured-purple {
	background-color: #dfcbf4;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-purple.png');
	}
}

.bg--textured-square {
	background-color: #fff0e5;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-squares.png');
	}
}

.bg--textured-shapes {
	background-color: #f3f3f3;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-shapes.jpg');
	}
}

.bg--textured-triangles {
	background-color: #e5e7f3;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-triangles.jpg');
	}
}

.bg--textured-diamonds {
	background-color: #ebeff4;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-diamonds.png');
	}

}

.bg--textured-maze {
	background-color: #f1f1f1;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-textured-maze.png');
	}
}

.bg--element {
	background-color: #222222;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-element.jpg');
	}
}

.bg--bars-and-squares {
	background-color: #f1f1f1;

	@media screen and (min-width: 768px) {
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/bg-bars-and-squares.png');
	}
}

#primary {
	.lazyload-bg {
		background-image: none !important;
	}
}
