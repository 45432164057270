#navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;

	// Logo
	.navbar-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: auto;

		svg {
			height: 25px;
			width: auto;

			@media screen and (max-width: 1439px) {
				max-height: 20px;
			}

			@media screen and (max-width: 480px) {
				width: 100%;
				height: auto;
			}
		}
	}

	.navbar-phone {
		padding: 0.5em 0.75em;
		border: 2px solid $brand-secondary;
		margin: 1em 0 0 15px;

		color: white;
		background-color: transparent;

		transition: background-color 0.2s ease-in-out;

		@media screen and (max-width: 1199px) {
			display: none !important;
		}

		&:hover,
		&:active,
		&:focus {
			background-color: #f26722;
			border-color: #f26722;
		}
	}

	ul {
		margin: 0;
		padding: 0;

		list-style: none;

		//All Links
		a {
			display: block;

			width: 100%;

			padding: 1em;

			font-size: pxrem(16);
			font-weight: 500;
			text-decoration: none;

			@media screen and (max-width: 1439px) {
				font-size: pxrem(14);
			}
		}

		li {
			margin: 0;

			/*
				Add chevron to items with a dropdown menu
			*/
			&.menu-item-has-children {
				> a {
					display: flex;
					align-items: center;

					&::after {
						content: '';
						background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/chevron-down.svg');
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center center;

						margin-left: 2px;
						margin-top: -2px;

						width: 24px;
						height: 16px;

						//Icon is black and since it's being loaded as a background image we invert to make it white;
						filter: invert(1);

						transform-origin: center center;
						transition: transform 150ms ease-in-out;
					}
				}
			}

			ul.sub-menu {
				li {
					min-width: auto;

					a {
						@media screen and (max-width: 1439px) {
							padding: 15px 10px;
						}
					}
				}
			}
		}
	}
}
