$bld-reflex-blue: #001489;

$bld-orange-mid: #ff6700;
$bld-orange-bright: #ff9803;

$bld-purple-mid: #450087;
$bld-purple-bright: #9206ff;

$bld-pink-mid: #870067;
$bld-pink-bright: #ff08b9;

// Standard Colors
$white: #ffffff;
$gray-k-10: #e5e5e5;
$gray-k-20: #cccccc;
$gray-k-30: #b2b2b2;
$gray-k-40: #999999;
$gray-k-50: #808080;
$gray-k-60: #666666;
$gray-k-70: #4d4d4d;
$gray-k-80: #333333;
$gray-k-90: #1a1a1a;
$black: #000000;

// Branding Colors
$brand-primary: $bld-reflex-blue;
$brand-secondary: $bld-orange-bright;
$brand-tertiary: $bld-purple-mid;

// Font Colors
$font-color-base: $gray-k-80;
$font-color-light: $white;
$font-color-dark: $gray-k-80;
$font-color-link: $brand-primary;
$font-color-link-hover: darken($brand-primary, 10%);

// BLD Branding
$bld-primary: #20429b;

// Generate Maps
$brand-colors: (
	'primary': $brand-primary,
	'secondary': $brand-secondary,
	'tertiary': $brand-tertiary
);
