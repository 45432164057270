// Desktop Nav

/*
	#site-wrapper::before is required to get the correct blend mode affect for the navigation background.
	mix-blend-mode will only blend with the background directly below it. Adding the mix-blend-mode to
	the #navabar element will case the whole element and it's children to blend.
*/

@media screen and (min-width: 992px) {
	#site-wrapper::before {
		content: ' ';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 94px;
		z-index: 10;

		background: $blue-to-dark-purple;
		mix-blend-mode: multiply;
	}

	#navbar {
		.navbar-menu {
			height: 100%;
			position: relative;
			padding: 10px 0 15px 0;

			.container {
				display: flex;
				align-items: center;
				height: 100%;
			}

			// Navigation
			ul.navbar-nav {
				display: flex !important;
				flex-flow: row nowrap;
				justify-content: flex-end;

				flex: 1 1 0;

				padding-top: 1em;

				//Top level links
				> li {
					background: transparent;
					position: relative;

					&::after {
						content: '';
						position: absolute; left: 1em; right: 1em; bottom: 5px;
						height: 2px;
						border-bottom: 2px solid transparent;
						transition: border 150ms ease-in-out;
					}
					&:hover::after {
						border-bottom: 2px solid $brand-secondary;
					}

					// Navigation Links
					> a {
						font-size: 0.9375rem;
						font-weight: 300;
						color: white;
						text-decoration: none;
						text-transform: uppercase;
						letter-spacing: 0.03em;
					}
				}

				//Other links
				li {
					position: relative;

					margin-bottom: 0;

					// First Dropdown
					ul.sub-menu {
						display: none;
						top: 100%; left: 1em;
						z-index: 9999;

						min-width: 200px;

						padding: 0;
						margin: 0;

						list-style: none;

						background-color: $brand-secondary;

						> li {
							> a {
								color: white;

								&:hover {
									color: $brand-primary;
								}
							}

							// Menu Has Children
							&.menu-item-has-children {
								> a {
									&::after {
										transform: rotate(-90deg);
									}
								}
							}
						}

						// Second Dropdown
						ul {
							left: 100%;
							top: 0;
						}
					}

					//Mega nav style for the services dropdown
					&.mega-nav {
						//Set display: flex on the containers to line up sub-menus horizontally
						&:hover {
							> ul.sub-menu {
								display: flex;
								flex-flow: row nowrap;
								align-items: flex-start;

								ul.sub-menu {
									display: block !important;
									position: relative;
									left: initial;
								}
							}
						}

						//Reduce the min-width of the sub-menus to prevent overflow of small devices
						> ul.sub-menu {
							padding: 15px 0;
							box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);

							> li {
								flex: 1 1 auto;

								// Menu Has Children
								&.menu-item-has-children {
									> a {
										&::after {
											content: none;
										}
									}
								}

								&:not(:first-child) {
									> a {
										//Set the the column headers to uppercase and prevent text wrapping
										text-transform: uppercase;
									}
								}

								> a {
									//Set the the column headers to uppercase and prevent text wrapping
									white-space: nowrap;
								}

								ul.sub-menu {
									li {
										a {
											padding: 10px 15px;
										}
									}
								}
							}

							@media screen and (max-width: 1499px) {
								left: -50%;
							}
							@media screen and (max-width: 1199px) {
								left: -100%;
							}
						}
					}

					&:hover,
					&:active,
					&:focus {
						> ul {
							display: block;
							position: absolute;

							li {
								float: none;
							}
						}
					}
				}

				@media screen and (max-width: 1439px) {
					padding-top: 0.8em;
				}
			}

			.bld-contact {
				display: none;
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

	#navbar .navbar-menu ul.navbar-nav > li > a {
		font-size: 0.875rem;
		padding: 1em 0.5em;
	}

}
