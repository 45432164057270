// Hide Nav Mobile on Desktop
.navbar-mobile {
	display: none;
}

// Pull Out Navigation
@media screen and (max-width: 991px) {
	#search {
		display: none !important;
	}

	// When Expanded
	.expanded-nav {
		header#navbar {
			nav.navbar-menu {
				opacity: 1;
				transform: translateX(0%);
			}
		}
	}

	// Standard Styles
	#navbar {
		// Mobile Top Bar
		.navbar-mobile {
			position: relative;

			display: flex;
			align-items: center;
			justify-content: space-between;

			padding: 0.5em 1em;

			background-image: $blue-to-bright-purple;

			z-index: 1000;

			/* The design called for a skinnier menu button; Easier to do it with CSS */
			.navbar-mobile-menu {
				width: 25px;
				height: 25px;

				padding: 0;

				background-color: transparent;
				border: none;

				line-height: 1;
				cursor: pointer;

				svg {
					width: 100%;
					max-height: 25px;

					fill: white;
				}

				&__open {
					.expanded-nav & {
						display: none;
					}
				}

				&__close {
					display: none;

					.expanded-nav & {
						display: initial;
					}
				}
			}

			.navbar-logo {
				margin: 0 30px;
			}

			.phone {
				width: 20px;

				font-size: pxrem(0);
				color: $white;

				opacity: 1;

				transition: opacity 150ms ease-in-out;

				svg {
					width: 100%;
					height: auto;
					fill: white;
				}

				.expanded-nav & {
					opacity: 0;
				}
			}

			@media screen and (max-width: 479px) {
				min-height: 48px;
			}
		}

		.navbar-menu {
			display: block;
			position: fixed;
			top: 48px;
			bottom: 0;

			width: 100%;
			max-width: 480px;

			background-color: $bld-orange-bright;

			box-shadow: -5px 5px 10px 10px transparentize($black, 0.5);
			overflow: hidden;

			opacity: 0;
			transform: translateX(-100%);
			transition: all $navigation__open-speed ease-in-out;

			.container {
				display: flex;
				flex-flow: column nowrap;
				align-items: flex-start;

				padding: 0;

				height: 100%;

				overflow-y: auto;
			}

			// Logo
			.navbar-logo {
				display: none;
			}

			// Navigation
			ul.navbar-nav {
				flex-direction: column;
				width: 100%;
				background-color: $bld-orange-bright;

				// Overrides search bar JS
				display: block !important;
				opacity: 1 !important;

				padding: 15px;

				li {
					// Current Item
					&.current-menu-item {
						> a {
							color: $brand-primary;
						}

						// Current Item Children
						> ul {
							position: relative;
						}
					}

					// First Dropdown
					ul.sub-menu {
						display: none;
						top: initial;
						border: none;

						// Second Dropdown
						ul {
							left: 0 !important;
							right: 0 !important;
						}

						a {
							padding-left: 10px;
						}

						ul.sub-menu {
							a {
								padding-left: 20px;
							}
						}
					}

					&.menu-item-has-children {
						> a {
							justify-content: space-between;

							&::after {
								margin: 0 10px;
							}
						}

						&:hover {
							> ul.sub-menu {
								display: block;
							}

							> a {
								&::after {
									transform: rotate(180deg);
								}
							}
						}
					}

					a {
						padding: 8px 0;
						border-bottom: 1px solid transparentize(white, 0.7);

						font-size: pxrem(20);
						font-weight: 400;
						color: $white;

						&:hover,
						&:active,
						&:focus {
							color: $brand-primary;
						}
					}
				}
			}
		}

		.bld-contact {
			margin-top: auto;
			padding: 15px;
			color: white;

			a {
				color: white;
			}
		}
	}
}
