// Sticky Navigation
@if $navigation__sticky == true {
	body.sticky {
		header#navbar {
			position: fixed;
			top: 0;
			width: 100%;
			max-width: 100%;
			margin: 0 auto;
			padding: 0;
			z-index: 100000;
			box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.35);
		}
	}
} @else {
	#bld-navigation-placeholder {
		display: none;
	}

	@include media-breakpoint-down($navigation__breakpoint) {
		#bld-navigation-placeholder {
			display: block;
		}

		body.sticky {
			header#navbar {
				position: fixed;
				top: 0;
				width: 100%;
				max-width: 100%;
				margin: 0 auto;
				padding: 0;
				z-index: 9999999999;
			}
		}
	}
}
