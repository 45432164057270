$btn-base-font-color: white;
$btn-disabled-bg: #f1f1f1;
$btn-disabled-color: #bababa;

.btn {
	display: inline-block;
	position: relative;

	padding: 20px 55px;
	border-radius: 0;

	font-size: pxrem(19);
	font-weight: 500;
	text-align: center;

	cursor: pointer;

	@media screen and (max-width: 767px) {
		padding: 13px 36px;
	}
}

%disabled {
	&.disabled,
	&:disabled {
		background-color: $btn-disabled-bg;
		border-color: $btn-disabled-bg;
		color: $btn-disabled-color;

		cursor: not-allowed;

		&:hover,
		&:focus {
			background-color: $btn-disabled-bg;
			border-color: $btn-disabled-bg;
			color: $btn-disabled-color;
		}
	}
}

.btn-primary {
	@extend .btn;
	@extend %disabled;

	color: $btn-base-font-color;
	background-color: $brand-primary;
	border: 2px solid $brand-primary;

	&:hover,
	&:active,
	&:focus {
		background-color: darken($brand-primary, 10%);
		border-color: darken($brand-primary, 10%);
	}

	/*
		Generate the "empty" buttons styles
		.btn-<style>--empty
	*/
	&--empty {
		@extend .btn-primary;
		@extend %disabled;

		color: $brand-primary;
		background-color: $btn-base-font-color;

		&:hover,
		&:active,
		&:focus {
			background-color: $brand-primary;
			border-color: $brand-primary;
			color: $btn-base-font-color;
		}
	}
}

.btn-secondary {
	@extend .btn;
	@extend %disabled;

	color: $btn-base-font-color;
	background-color: $brand-secondary;
	border: 2px solid $brand-secondary;

	&:hover,
	&:active,
	&:focus {
		background-color: #f26722;
		border-color: #f26722;
		color: $btn-base-font-color;
	}

	/*
		Generate the "empty" buttons styles
		.btn-<style>--empty
	*/
	&--empty {
		@extend .btn-secondary;
		@extend %disabled;

		color: $brand-secondary;
		background-color: $btn-base-font-color;

		&:hover,
		&:active,
		&:focus {
			background-color: $brand-secondary;
			border-color: $brand-secondary;
			color: $btn-base-font-color;
		}
	}
}

.btn-caret {
	&::after {
		content: '';
		background-image: url('/wp-content/themes/bluelaserdigital-v3/assets/img/chevron-right-purple.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;

		display: inline-block;
		width: 16px;
		height: 10px;

		margin-left: 5px;

		text-decoration: none;

		transform: translateX(0);
		transition: transform 100ms ease-in-out;
	}

	&:hover {
		&::after {
			text-decoration: none;
			transform: translateX(5px);
		}
	}
}
