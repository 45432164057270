@use 'sass:math';

// Create the container
@mixin make-container() {
	width: 100%;
	padding: 0 math.div($grid-gutter-width, -2);
	margin: 0 auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

// Create the row using flex
@mixin make-row() {
	margin: 0 math.div($grid-gutter-width, -2);
}

// Full-Width Fluid Container
.container-fluid {
	@include make-container();
}

// Standard Responsive Container
.container {
	@include make-container();
	@include make-container-max-widths();
}

.row {
	@include make-row();
}
