.bld-feature {
	display: flex;
	flex-flow: column-reverse;

	position: relative;

	height: 600px;

	color: $white;

	background-size: cover;
	background-position: center center;

	overflow: hidden;

	&__image {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;

		overflow: hidden;

		img {
			width: 100%;
			height: 100%;

			object-fit: cover;
			object-position: center center;
		}
	}

	.container {
		position: relative;
	}

	&__box {
		max-width: 450px;

		padding: 30px 20px;
		margin-top: 15px;

		background-color: transparentize($bld-reflex-blue, .1);
		box-shadow: 15px 15px 0 transparentize($black, .5);

		a {
			color: white;
			text-decoration: none;
		}
	}

	&__super-title {
		display: inline-block;
		@extend .super-title;
		margin-bottom: 10px;
	}

	&__title {
		@extend %bld-heading--white;
		@extend %bld-heading--lg;

		margin: 0;
	}

	&__meta {
		display: flex;
		align-items: center;

		margin-top: 10px;
	}

	&__date {
		display: inline-block;
		margin-top: 10px;
	}

	&__tax {
		display: flex;
		flex-flow: row nowrap;

		&::before {
			content: " | ";
			margin: 0 10px;
		}

		li {
			margin: 0;

			> a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}


	@media screen and (max-width: 1439px) {
		height: calc(1439px / 2.666);
	}

	@media screen and (max-width: 1199px) {
		height: calc(1199px / 2.666);
	}

	@media screen and (max-width: 991px) {
		height: calc(991px / 2.666);
	}

	@media screen and (max-width: 767px) {
		height: 400px;
	}
}
