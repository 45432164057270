h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1,
h2 {
	margin-top: 0;
}

$header-color-map: (
	'primary': $brand-primary,
	'secondary': $brand-secondary,
	'white': $white,
);

$header-size-map: (
	'xl': 48,
	'lg': 40,
	'md': 32,
	'sm': 27,
	'xs': 21,
);

.bld-heading {
	@extend %bld-heading;

	@each $name, $color in $header-color-map {
		&--#{$name} {
			@extend %bld-heading--#{$name};
		}
	}

	@each $name, $size in $header-size-map {
		&--#{$name} {
			@extend %bld-heading--#{$name};
		}
	}
}

//Prevent white text from blending into the WYSIWYG background
#tinymce {
	.bld-heading--white {
		color: black !important;
	}
}

.super-title {
	font-size: pxrem(13);
	font-weight: 300;
	letter-spacing: 0.2em;

	text-transform: uppercase;
}

hr.bld-hr {
	display: inline-block;
	width: 100%;
	max-width: 100px;
	height: 1px;
	background-color: $bld-reflex-blue;

	margin: 40px 0;
}

.bld-col--2 {
	@media screen and (min-width: 768px) {
		columns: 2;
	}
}
